import React, { useEffect, useState } from "react";
import useAuthStore from "../../../store/authStore";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import VideoPopup from "../../popup/VideoPopup";
import SetUpGuide from "../SetUpGuide";
import { useNavigate } from "react-router-dom";
import PopupPage from "../../popup/PopupPage";
import { ReactComponent as PlayIcon } from "../../../assets/svg/playIcon.svg";
import gabarEnv from "../../../assets/images/gabar-env.png";
import "../../../style/components/store/SelectEnv.scss";
import "../../../style/components/store/EnvStore.scss";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SelectEnv() {
  const { token, setLoading } = useAuthStore();
  const [envList, setEnvList] = useState({});
  const [playVideoPopup, setVideoPopup] = useState(false);
  const [videoData, setVideoData] = useState("");
  const [selectedEnvInfo, setSelectedEnvInfo] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [openPopup, setPopupOpen] = useState(false);
  const [openPopupSetupGuide, setOpenPopupSetupGuide] = useState(false);
  const videoSrc= "https://conceptstorebucket.s3.amazonaws.com/appzlogic/Platform-Guide.mp4";

  const navigate = useNavigate();

  useEffect(() => {
    async function getEnv() {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.environmentTemplate}?page=1&limit=10`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      const info = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info?.status === 200) {
        setCurrentStep(info.data.data.completed_step);
      }
      if (response?.status === 200) {
        setEnvList(response.data.data);
      }
      if (response?.status === 401 || response?.status === 500) {
        navigate("/login");
      }
      setLoading(false);
    }
    getEnv();
  }, []);

  const handlePopup = (data) => {
    setVideoPopup(data);
  };

  const playPopup = (data) => {
    setVideoData(data.video_url);
    setSelectedEnvInfo({
      title: data.template_name,
      template_features:data.template_features,
      description: data.template_description,
      id: data.env_template_id,
    });
    setVideoPopup(!playVideoPopup);
  };

  const handlePopup2 = (data) => {
    setPopupOpen(false);
  };

  // const playPopup = () => {
  //   setVideoData("https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/sandbox-environment/videos/Futuristic+Environment+-+Popup+Video.mp4");
  //   setSelectedEnvInfo({
  //     title: "Futuristic Virtual Retail",
  //     template_features:[
  //       "Products: Display up to 10 products on illuminated light boxes",
  //       "Suitable for: Make-up, Skincare, Shoes, Bags",
  //       "Customisable Area: Wall and Floor"
  //   ],
  //     description: "Step into a futuristic shopping space with sleek, curved walls and a stunning centrepiece of four large video panels topped with your logo. This immersive environment is designed to captivate and showcase your premium products.",
  //     id: "4",
  //   });
  //   setVideoPopup(!playVideoPopup);
  // };

  // const handlePopup2 = (data) => {
  //   setPopupOpen(false);
  // };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="env-store-add-product-heading">
        <h2>Select and Customise Your Virtual Retail</h2>
        <div className="stepper-container">
          <div className="stepper">
            <div className="round active">1</div>
            <div
              className="round step-not-active"
              onClick={() => navigate("/dashboard/select-store")}
            >
              2
            </div>
            <div
              className="round step-not-active"
              onClick={() => {
                if (currentStep < 3) {
                  setPopupOpen(true);
                } else {
                  navigate("/dashboard/customize-store");
                }
              }}
            >
              3
            </div>
            <div
              className="round step-not-active"
              onClick={() => navigate("/dashboard/add-game")}
            >
              4
            </div>
            <div className="round step-not-active">5</div>
          </div>
          <div className="setupguide-btn">
            <button onClick={() => setOpenPopupSetupGuide(true)}>
              View Setup Guide
            </button>
          </div>
        </div>
      </div>
      {/* <div
        className="env-slider-container"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className="item"
          onClick={() => playPopup()}
          style={{ width: "max-content" }}
        >
          <img src={gabarEnv} alt="img" style={{ cursor: "pointer" }} />
          <span className="play-icon">
            <PlayIcon cursor={"pointer"} />
          </span>
          <span className="title">Futuristic Virtual Retail</span>
        </div>
      </div> */}
        <div className="env-slider-container">
              <Slider {...settings}>
                {envList.length > 0 ? (
                  envList.map((item, index) => (
                    <div className="item" key={index} onClick={() => playPopup(item)}>
                      <img src={item.video_thumbnail_url} alt="img" style={{cursor:'pointer'}}/>
                      <span className="play-icon" >
                        <PlayIcon cursor={'pointer'}/>
                      </span>
                      <span className="title"> {item.template_name}</span>
                    </div>
                  ))
                ) : (
                  <p>Loading...</p>
                )}
              </Slider>
            </div>
      {playVideoPopup && (
        <VideoPopup
          open={playVideoPopup}
          videoUrl={videoData}
          closePopup={handlePopup}
          data={selectedEnvInfo}
        />
      )}
      {openPopupSetupGuide && (
        <SetUpGuide
          openPopupSetupGuide={openPopupSetupGuide}
          setOpenPopupSetupGuide={setOpenPopupSetupGuide}
          videoSrc = {videoSrc}
        />
      )}
      {openPopup && <PopupPage closePopup={handlePopup2} />}
    </>
  );
}

export default SelectEnv;
