import React, { useEffect, useState } from "react";
// import CongratulationsBgImage from "../../../assets/images/rectangle47.png";
// import { ReactComponent as ViewLiveIcon } from "../../../assets/svg/Maximize.svg";
import { ReactComponent as DotsIcon } from "../../../assets/svg/dots.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrow.svg";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import useAuthStore from "../../../store/authStore";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../style/components/dashboard/Congratulation.scss";
import "../../../style/components/Card.scss";
import "../../../style/components/store/Enviroment.scss";
import { Tooltip } from "@mui/material";

export default function Enviroment() {
  const [uuid, setUuid] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [varientsVal, setVarientVal] = useState();
  const [varientsDetail, setVarientDetail] = useState(null);
  const [envNameDeatil, setEnvDeatil] = useState({
    name: "",
    thumbnail: "",
  });
  const { token, user } = useAuthStore();
  const company = user.company;
  const userName = user.first_name;

  useEffect(() => {
    const getEnvDetails = async () => {
      setLoading(false);
      const response = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response?.statusCode === 401 || response?.statusCode === 500) {
        navigate("/login");
      } else if (response?.data?.statusCode === 200) {
        setUuid(response.data.data.brand_param);
        setEnvDeatil({
          name: response?.data?.data?.env_template_master?.template_name ?? "",
          thumbnail:
            response?.data?.data?.env_template_master?.video_thumbnail_url ??
            "",
        });
        setVarientVal(response?.data?.data?.variant_detail?.length ?? 0);
        setVarientDetail(response?.data?.data?.variant_detail);
      }

      // const response2 = await fetchData(
      //   `${ApiConfig.info}`,
      //   setLoading,
      //   "post",
      //   {},
      //   { authorization: `Bearer ${token}` }
      // );
      // if (response2?.statusCode === 401 || response2?.statusCode === 500) {
      //   navigate("/login");
      // } else if (response2.data.statusCode === 200) {
      //   // setSelectedEnv(response2.data.data.envDetail.env_template_id);
      // }
      setLoading(true);
    };
    getEnvDetails();
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };
  function navigateEnv() {
    if (uuid !== null) {
      window.open(`/${company}/playAround/${uuid}`, "_blank");
    } else {
      navigate(`/dashboard`);
    }
  }
  return (
    <div className="congratulations-heading enviroment">
      <div className="congrats-heading">
        <h2>Your Virtual Retail</h2>
        <p>Customise your Environment.</p>
      </div>
      <div className="congratulations-container">
        <div className="personlisation-slider">
          <p className="live-text">Live Environments</p>
          <div className="slider">
            <Slider {...settings}>
              {varientsDetail !== null &&
                varientsDetail.map((data, index) => (
                  <div
                    className="item"
                    key={index}
                    onClick={() =>
                      window.open(
                        `/${user.company}/playAround/${uuid}?variant_id=${data.variant_id}`,
                        "_blank"
                      )
                    }
                  >
                      <Tooltip
                      title={`Edit Environment - ${data.variant_id}`}
                      placement="bottom"
                      arrow
                    >
                      <DotsIcon
                        className="dots"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          navigate("/dashboard/customize-enviroment", {
                            state: { variation: data.variant_id },
                          });
                        }}
                      />
                    </Tooltip>
                    <img
                      src={envNameDeatil.thumbnail}
                      alt="img"
                      style={{ cursor: "pointer" }}
                    />
                    <div className="name-tag">
                      <button>
                        {data.title}
                        <ArrowIcon />
                      </button>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
