import React, { useEffect, useState } from "react";
import Campaign from "./experience/Campaign";
import UI from "./ui/UI";
import Loading from "./ui/Loading/Loader";
import useAudioContainer from "./util/useAudioContainer";
// import AddtoCart from "../AddtoCart";
import Cart from "../Cart";
import PopupGame from "../game/PopupGame";
import AddtoCart2 from "../AddtoCart2";
import useStore from "./util/3dstore";

const Gabar = ({
  variation = 0,
  productData,
  audioFile,
  cartProductDetails,
  gameData,
  variationID = 1 ,
}) => {
  const { isCartOpen, isProductCartOpen, productDatas, closeProductCart } =
    useAudioContainer();
  const { isEntryAnimationDone } = useStore();

  const handlePopup = () => {
    closeProductCart();
  };
  return (
    <div className="GabarApp" style={{ height: "100%", position: "relative" }}>
      <Campaign
        variation={variation}
        productData={productData}
        cartProductDetails={cartProductDetails}
        variationID = {variationID} 
      />
      <UI musicUrl={audioFile} variation={variation} />
      <Loading />
      {variation === 3 && (
        <>
          {isProductCartOpen && (
            <>
              <AddtoCart2 data={productDatas} closePopup={handlePopup} />
            </>
          )}
          {isCartOpen && <Cart />}
          {gameData !== null && isEntryAnimationDone && gameData.length > 0 && (
            <PopupGame gameData={gameData} />
          )}
        </>
      )}
    </div>
  );
};

export default Gabar;
