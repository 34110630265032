import lightIcon from "../../assets/images/ion_rocket-outline.png";
import "../../style/components/popup/LaunchPopup.scss";

function LaunchPopup(props) {


  return (
    <div className="background2 popup">
      <div className="textContainer">
        <img src={lightIcon} alt="errorIcon" />
        <h2>Launching ....</h2>
        <p>This may take 2 to 5 days. Please contact support@onewayx.com for assistance.</p>
      </div>
    </div>
  );
}

export default LaunchPopup;
