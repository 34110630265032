import React, { useEffect, useState } from "react";
import Gabar from "../../../enviroments/gabar/Gabar";
import "../../../style/components/dashboard/CustomizeStore.scss";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import useAuthStore from "../../../store/authStore";
import { useNavigate, useLocation } from "react-router-dom";
import useVideoStore from "../../../enviroments/gabar/util/video";
import useStore from "../../../enviroments/gabar/util/3dstore";
import Spinner from "../../../helper/Spinner";

const CustomizeStore = () => {
  const [productData, getProductData] = useState(null);
  const { handleVideoFileData, handleAudioFileData } = useVideoStore();
  const [isLoading, setLoading] = useState(true);
  const { token } = useAuthStore();
  const navigate = useNavigate();
  const { handleReset } = useStore((state) => state);
  const location = useLocation();
  const variationID = location?.state?.variation ?? 1; 

  useEffect(() => {
    handleReset();
    const getEnvDetails = async () => {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.productList}?page=1&limit=100&variant_id=${variationID}`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response?.statusCode === 401 || response?.statusCode === 500) {
        navigate("/login");
      } else if (response?.data?.statusCode === 200) {
        getProductData(response.data.data);
      }

      const file = await fetchData(
        `${ApiConfig.fileMapping}?variant_id=${variationID}`,
        setLoading,
        "GET",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (file?.status === 401 || file?.status === 500) {
        navigate("/login");
      } else if (file?.status === 200) {
        if (file?.data?.data[0]?.audio_url !== null) {
          handleAudioFileData(file?.data?.data[0]?.audio_url);
        }
        if (file?.data?.data[0]?.video_url !== null) {
          handleVideoFileData(file?.data?.data[0]?.video_url);
        }
      }

      setLoading(false);
    };
    getEnvDetails();
  }, []);

  return (
    <div style={{ height: "calc( 100vh - 53px)" }} className="custEnv">
      {!isLoading ? (
        <Gabar variation={2} productData={productData} variationID={variationID} />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CustomizeStore;
