import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ApiConfig from "../../../helper/ApiConfig";
import { fetchData } from "../../../fetcher/fetchData";
import useAuthStore from "../../../store/authStore";
import { ReactComponent as BackwardArrowIcon } from "../../../assets/svg/backwardArrow.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../../style/components/game/Game.scss";
import "../../../style/components/store/SelectEnv.scss";
import "../../../style/components/store/EnvStore.scss";
import "../../../style/components/game/QuizGame.scss";

export default function QuizGame() {
  const navigate = useNavigate();
  const { token, setLoading } = useAuthStore();
  const customButtonSX = {
    marginLeft: "5px",
    marginRight: "10px",
    color: "#EE3A24",
    borderColor: "#EE3A24",
    backgroundColor: "#fff",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#EE3A24",
    },
  };

  const [questionaire, setQuestionaire] = useState([]);
  const [quizActiveQuestion, setQuizActiveQuestion] = useState(null);
  const trigger_limit = JSON.parse(localStorage.getItem("trigger_limit"));
  const coupon_code = JSON.parse(localStorage.getItem("coupon_code"));

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const response = await await fetchData(
          `${ApiConfig.gameData}`,
          setLoading,
          "get",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (response?.status === 401 || response?.status === 500) {
          navigate("/login");
        } else if (response?.status === 200) {
          if (response.data.data[0]?.game_quiz_masters[0].question) {
            const temp = JSON.parse(
              response.data.data[0].game_quiz_masters[0].question
            );
            setQuizActiveQuestion(temp);
            formik.setFieldValue("question", temp[0].question || "");
            formik.setFieldValue("option1", temp[0].options[0] || "");
            formik.setFieldValue("option2", temp[0].options[1] || "");
            formik.setFieldValue("option3", temp[0].options[2] || "");
            formik.setFieldValue("correctAnswer", temp[0].correct_answer || "");
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
      setLoading(false);
    };
    getData();
  }, []);

  const handleFormSubmit = () => {
    saveQuestionaire();
    handleFormVal();
  };

  const handleFormVal = () => {
    if (
      quizActiveQuestion[activeQuestionaire] !== null &&
      quizActiveQuestion[activeQuestionaire] !== undefined
    ) {
      formik.setFieldValue(
        "question",
        quizActiveQuestion[activeQuestionaire].question || ""
      );
      formik.setFieldValue(
        "option1",
        quizActiveQuestion[activeQuestionaire].options[0] || ""
      );
      formik.setFieldValue(
        "option2",
        quizActiveQuestion[activeQuestionaire].options[1] || ""
      );
      formik.setFieldValue(
        "option3",
        quizActiveQuestion[activeQuestionaire].options[2] || ""
      );
      formik.setFieldValue(
        "correctAnswer",
        quizActiveQuestion[activeQuestionaire].correct_answer || ""
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      question: "",
      option1: "",
      option2: "",
      option3: "",
      correctAnswer: "",
    },
    validationSchema: Yup.object({
      question: Yup.string().required("Question cannot be left blank!"),
      option1: Yup.string().required("Plese fill the option!"),
      option2: Yup.string().required("Plese fill the option!"),
      option3: Yup.string().required("Plese fill the option!"),
      correctAnswer: Yup.string().required("Plese select the correct answer!"),
    }),
    onSubmit: handleFormSubmit,
  });

  const saveQuestionaire = () => {
    const activeQuestionaireIndex = activeQuestionaire - 1;
    const nextQuestionaireIndex = activeQuestionaire;
    if (questionaire[activeQuestionaireIndex] !== undefined) {
      let questionaieCopy = questionaire;
      questionaieCopy[activeQuestionaireIndex] = {
        question: formik.values.question,
        option1: formik.values.option1,
        option2: formik.values.option2,
        option3: formik.values.option3,
        correctAnswer: formik.values.correctAnswer,
      };
      setQuestionaire([...questionaieCopy]);
      if (questionaire[nextQuestionaireIndex] !== undefined) {
        formik.setValues({
          question: questionaire[nextQuestionaireIndex].question,
          option1: questionaire[nextQuestionaireIndex].option1,
          option2: questionaire[nextQuestionaireIndex].option2,
          option3: questionaire[nextQuestionaireIndex].option3,
          correctAnswer: questionaire[nextQuestionaireIndex].correctAnswer,
        });
      } else {
        formik.resetForm();
      }

      setActiveQuestionaire((prevState) => prevState + 1);
    } else {
      setQuestionaire((prevState) => [
        ...prevState,
        {
          question: formik.values.question,
          option1: formik.values.option1,
          option2: formik.values.option2,
          option3: formik.values.option3,
          correctAnswer: formik.values.correctAnswer,
        },
      ]);
      setActiveQuestionaire((prevState) => prevState + 1);
      formik.resetForm();
    }
  };
  const loadPreviousQuestion = () => {
    let previousQuestionIndex = activeQuestionaire - 2;
    formik.setValues({
      question: questionaire[previousQuestionIndex].question,
      option1: questionaire[previousQuestionIndex].option1,
      option2: questionaire[previousQuestionIndex].option2,
      option3: questionaire[previousQuestionIndex].option3,
      correctAnswer: questionaire[previousQuestionIndex].correctAnswer,
    });
    setActiveQuestionaire((prevState) => prevState - 1);
  };

  const [activeQuestionaire, setActiveQuestionaire] = useState(1);

  async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: 5 },
      { authorization: `Bearer ${token}` }
    );
    if (response?.data?.statusCode === 200) {
      navigate("/dashboard");
    }
  }

  const submitQuestionaire = async () => {
    var transformedArray = questionaire.map((item) => {
      let correctOption;
      if (item.correctAnswer === "1") {
        correctOption = item.option1;
      } else if (item.correctAnswer === "2") {
        correctOption = item.option2;
      } else if (item.correctAnswer === "3") {
        correctOption = item.option3;
      }
      return {
        question: item.question,
        options: [item.option1, item.option2, item.option3],
        correct_answer: correctOption,
      };
    });

    let data = {
      game_id: 1,
      player_time_limit: 1,
      trigger_limit: trigger_limit,
      coupon_code: coupon_code,
      question: transformedArray,
    };

    try {
      const response = await fetchData(
        ApiConfig.gameMapping,
        setLoading,
        "post",
        data,
        { authorization: `Bearer ${token}` }
      );
      if (response?.status === 401 || response?.status === 500) {
        navigate("/login");
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    }
    localStorage.removeItem("trigger_limit");
    localStorage.removeItem("coupon_code");
    // navigate("/dashboard");
    updateStep();
  };

  return (
    <>
      <div
        style={{ position: "relative" }}
        className="env-store-add-product-heading"
      >
        <h2>Add Gamification</h2>
        <div className="stepper-container">
          <div className="stepper">
            <div className="round ">
              <Link to="/dashboard/select-enviroment">1</Link>
            </div>
            <div className="round">
              <Link to="/dashboard/select-store">2</Link> 
            </div>
            <div className="round ">
            <Link to="/dashboard/customize-store">3</Link>
            </div>
            <div className="round active">4</div>
            <div className="round step-not-active">5</div>
          </div>
          <div className="">
            <p
              className="select-gamification"
              onClick={() => navigate("/dashboard/add-game")}
            >
              {" "}
              <span style={{ position: "relative", top: "0.14vw" }}>
                <BackwardArrowIcon style={{ margin: "0 0.15vw" }} />
              </span>
              <span>Select Gamification</span>
            </p>
          </div>
        </div>
      </div>
      <div className="quiz-container">
        <div className="inner-quiz-game-container">
          <div className="store-heading">
            <h2> Add Questions</h2>
          </div>
          <Grid container justifyContent={"center"}>
            <Grid item lg={12} sx={{ backgroundColor: "#fff" }}>
              {activeQuestionaire < 11 && (
                <>
                  <Typography
                    color={"#EE3A24"}
                    className="ques-subheading"
                    textAlign={"center"}
                  >
                    {`Question ${activeQuestionaire} of 10`}
                  </Typography>
                  <Box component={"form"}>
                    <Grid container p={1}>
                      <Grid item lg={2} my={2} alignContent={"center"}>
                        <Typography className="add-ques-text">
                          Add Question
                        </Typography>
                      </Grid>
                      <Grid item lg={10} my={2} alignContent={"center"}>
                        <TextField
                          fullWidth
                          name="question"
                          id="question"
                          placeholder="Question"
                          value={formik.values.question}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.question &&
                            Boolean(formik.errors.question)
                          }
                          helperText={
                            formik.touched.question && formik.errors.question
                          }
                        />
                      </Grid>
                      <RadioGroup
                        row
                        aria-labelledby="correctAnswer"
                        value={formik.values.correctAnswer}
                        onChange={formik.handleChange}
                      >
                        <Grid item lg={2} my={1} alignContent={"center"}>
                          <Typography className="add-ques-text">
                            Option 1
                          </Typography>
                        </Grid>
                        <Grid item lg={7} my={1} alignContent={"center"}>
                          <TextField
                            fullWidth
                            name="option1"
                            id="option1"
                            placeholder="Answer"
                            onChange={formik.handleChange}
                            value={formik.values.option1}
                            error={
                              formik.touched.option1 &&
                              Boolean(formik.errors.option1)
                            }
                            helperText={
                              formik.touched.option1 && formik.errors.option1
                            }
                          />
                        </Grid>
                        <Grid item lg={3} my={1} alignContent={"center"}>
                          <FormControlLabel
                            value="1"
                            control={<Radio name="correctAnswer" />}
                            label="Correct Answer"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item lg={2} my={1} alignContent={"center"}>
                          <Typography className="add-ques-text">
                            Option 2
                          </Typography>
                        </Grid>
                        <Grid item lg={7} my={1} alignContent={"center"}>
                          <TextField
                            fullWidth
                            name="option2"
                            id="option2"
                            placeholder="Answer"
                            value={formik.values.option2}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.option2 &&
                              Boolean(formik.errors.option2)
                            }
                            helperText={
                              formik.touched.option2 && formik.errors.option2
                            }
                          />
                        </Grid>
                        <Grid item lg={3} my={1} alignContent={"center"}>
                          <FormControlLabel
                            value="2"
                            control={<Radio name="correctAnswer" />}
                            label="Correct Answer"
                            labelPlacement="start"
                          />
                        </Grid>
                        <Grid item lg={2} my={1} alignContent={"center"}>
                          <Typography className="add-ques-text">
                            Option 3
                          </Typography>
                        </Grid>
                        <Grid item lg={7} my={1} alignContent={"center"}>
                          <TextField
                            fullWidth
                            name="option3"
                            id="option3"
                            placeholder="Answer"
                            value={formik.values.option3}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.option3 &&
                              Boolean(formik.errors.option3)
                            }
                            helperText={
                              formik.touched.option3 && formik.errors.option3
                            }
                          />
                        </Grid>
                        <Grid item lg={3} my={1} alignContent={"center"}>
                          <FormControlLabel
                            value="3"
                            control={<Radio name="correctAnswer" />}
                            label="Correct Answer"
                            labelPlacement="start"
                          />
                        </Grid>
                      </RadioGroup>
                      <Grid item lg={9}></Grid>
                      <Grid item lg={3}>
                        <FormControl
                          error={
                            formik.touched.correctAnswer &&
                            Boolean(formik.errors.correctAnswer)
                          }
                        >
                          {formik.touched.correctAnswer &&
                            Boolean(formik.errors.correctAnswer) && (
                              <FormHelperText
                                style={{
                                  position: "relative",
                                  top: "-2.25vw",
                                  left: "0.98vw",
                                }}
                              >
                                Please select an answer!
                              </FormHelperText>
                            )}
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        textAlign={"center"}
                        mt={3}
                        className="quiz-tb-container"
                      >
                        {activeQuestionaire > 1 && (
                          <Button
                            className="prev-submit-quiz"
                            startIcon={<ArrowBackIcon />}
                            onClick={loadPreviousQuestion}
                            // sx={customButtonSX}
                            // variant="contained"
                          >
                            Previous Question
                          </Button>
                        )}

                        <Button
                          className="submit-quiz"
                          endIcon={<ArrowForwardIcon />}
                          // sx={customButtonSX}
                          // variant="contained"
                          onClick={formik.handleSubmit}
                        >
                          {activeQuestionaire < 10
                            ? "Save & Next Question"
                            : "Finish"}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        textAlign={"center"}
                        mt={3}
                        className="quiz-tb-container"
                      >
                        {activeQuestionaire > 3 && (
                          <Button
                            sx={customButtonSX}
                            variant="contained"
                            onClick={submitQuestionaire}
                          >
                            Save & Launch
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              {activeQuestionaire === 11 && (
                <>
                  <Box textAlign={"center"} my={2}>
                    <Typography my={3}>Your questionaire is ready!</Typography>
                    <Button
                      sx={customButtonSX}
                      variant="contained"
                      onClick={loadPreviousQuestion}
                    >
                      Go Back
                    </Button>
                    <Button
                      sx={customButtonSX}
                      variant="contained"
                      onClick={submitQuestionaire}
                    >
                      Submit Quiz
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}