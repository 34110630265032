import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { fetchData } from "../../fetcher/fetchData";
import ApiConfig from "../../helper/ApiConfig";
import useAuthStore from "../../store/authStore";
import Logo from "../../assets/images/white-logo.svg";
import { ReactComponent as MessageIcon } from "../../assets//svg/Message.svg";

export default function PasswordVerification() {
  const navigate = useNavigate();
  const { setLoading, token } = useAuthStore();
  const [disabled, setDisabled] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const value = localStorage.getItem("brand_email");
  const payload = {
    brand_email: value,
    type: 2,
  };

  async function handleSendLink() {
    setDisabled(true);
    const response = await fetchData(
      ApiConfig.resendLink,
      setLoading,
      "post",
      payload,
      { authorization: `Bearer ${token}` }
    );
    if (response?.data?.statusCode === 200) {
      navigate("/password-verification");
    }
  }

  useEffect(() => {
    let interval;
    if (disabled) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds === 1) {
            setDisabled(false);
            clearInterval(interval);
            return 30;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [disabled]);
  return (
    <div
      className="main-container"
      style={{
        background: "linear-gradient(90deg, #9747FF -1.09%, #EE3A24 103.17%)",
      }}
    >
      <header className="sigupHeader">
        <nav>
          <ul>
            <li>
              <Tooltip Tooltip title="Back to Website">
                <Link to="https://www.onewayx.com/" target="_blank">
                  <img src={Logo} alt="logo" />
                </Link>
              </Tooltip>
            </li>
          </ul>
        </nav>
      </header>
      <div className="email-verification-section">
        <div className="email-verification-container">
          <div className="header-container">
            <MessageIcon></MessageIcon>
            <div className="email-verification-heading">
              <h2>Check Your Email</h2>
              <p>Reset your password using the verification link</p>
            </div>
            <button
              className="button2"
              onClick={() => {
                handleSendLink();
              }}
              disabled={disabled}
              style={{ fontSize: "1.3875vw" }}
            >
              Resend
            </button>
            {disabled && (
              <p style={{ fontSize: "0.9396vw" }}>Resend in {seconds}s</p>
            )}
            <div className="return-login">
              <Link to="/login">Return to Login &rarr;</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
