// import React, { useEffect, useRef, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";

// import tabletFrame from "../assets/images/tablet.png";
// import placeholderSrc from "../assets/images/homepagePlaceholderImage.png";
// import { ReactComponent as PauseIcon } from "../assets/svg/pause-icon.svg";
// import { ReactComponent as SignupIcon } from "../assets/svg/signup-icon.svg";

// import "../style/pages/Home.scss";
// import Header from "../components/Header";
// import { fetchData } from "../fetcher/fetchData";
// import ApiConfig from "../helper/ApiConfig";
// import useAuthStore from "../store/authStore";
// import Spinner from "../helper/Spinner";

// function Home() {
//   const [playState, setPlayState] = useState(true);
//   const [videoLoaded, setVideoLoaded] = useState(false);
//   const { setLoading, loading, login, updateUser } = useAuthStore();
//   const videoRef = useRef();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const tokenData = localStorage.getItem("sessionState");
//     let userData = JSON.parse(tokenData);

//     if (tokenData !== null && userData.isAuthenticated === true) {
//       let token = userData.token;

//       updateUser(userData.user);
//       const getEnvDetails = async () => {
//         setLoading(true);
//         const response = await fetchData(
//           `${ApiConfig.env}`,
//           setLoading,
//           "post",
//           {},
//           { authorization: `Bearer ${token}` }
//         );
//         if (response?.status === 200) {
//           login(token);
//           navigate("/dashboard");
//         }
//         setLoading(false);
//       };
//       getEnvDetails();
//     } 
//   }, []);

//   const handleVideoLoaded = () => {
//     setVideoLoaded(true);
//   };

//   function pauseAndPlayVideo() {
//     if (videoRef.current.paused) {
//       videoRef.current.play();
//       setPlayState(true);
//     } else {
//       videoRef.current.pause();
//       setPlayState(false);
//     }
//   }
//   return (
//     <>
//       {!loading ? (
//         <>
//           <Header variation={1}></Header>
//           <section className="hero-section">
//             <div className="row row-1">
//               <div className="video-container" onClick={pauseAndPlayVideo}>
//                 <img
//                   src={tabletFrame}
//                   className="tablet-frame"
//                   alt="Tablet Frame"
//                 />
//                 <video
//                   ref={videoRef}
//                   className="video-file"
//                   width="100%"
//                   autoPlay
//                   muted
//                   loop
//                   onLoadedData={handleVideoLoaded}
//                   style={{
//                     display: videoLoaded ? "block" : "none",
//                     width: "100%",
//                   }}
//                 >
//                   <source
//                     src="https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/landing-page/Platform-Guide.mp4"
//                     type="video/mp4"
//                   />
//                 </video>
//                 {!videoLoaded && (
//                   <img
//                     src={placeholderSrc}
//                     alt="Video placeholder"
//                     style={{ width: "100%", height: "auto" }}
//                   />
//                 )}
//                 {!playState ? (
//                   <PauseIcon
//                     className="pause-icon"
//                     onClick={() => {
//                       videoRef.current.play();
//                       setPlayState(true);
//                     }}
//                   ></PauseIcon>
//                 ) : null}
//               </div>
//               <div className="col col-5 col-b" style={{ minWidth: "34%" }}>
//                 <h2 className="heading">
//                   BRING YOUR <br />
//                   BRAND EXPERIENCE
//                   <br /> TO LIFE
//                 </h2>
//                 <p className="text">
//                   Transform your 2D websites into 3D immersive virtual stores
//                 </p>
//                 <div className="cta-container">
//                   <div></div>
//                   <div>
//                     <Link to="/signup" className="button">
//                       <SignupIcon />
//                       <span>Sign Up</span>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </>
//       ) : (
//         <div
//           style={{
//             width: "100vw",
//             height: "100vh",
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Spinner />
//         </div>
//       )}
//     </>
//   );
// }

// export default Home;


import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import tabletFrame from "../assets/images/tablet.png";
import placeholderSrc from "../assets/images/homepagePlaceholderImage.png";
import { ReactComponent as PauseIcon } from "../assets/svg/pause-icon.svg";
import { ReactComponent as SignupIcon } from "../assets/svg/signup-icon.svg";

import "../style/pages/Home.scss";
import Header from "../components/Header";
import { fetchData } from "../fetcher/fetchData";
import ApiConfig from "../helper/ApiConfig";
import useAuthStore from "../store/authStore";
import Spinner from "../helper/Spinner";

function Home() {
  const [playState, setPlayState] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const { login, updateUser } = useAuthStore();
  const [loading, setLoading ] = useState(false);
  const videoRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const tokenData = localStorage.getItem("sessionState");
    let userData = JSON.parse(tokenData);

    if (tokenData !== null && userData.isAuthenticated === true) {
      let token = userData.token;

      updateUser(userData.user);
      const getEnvDetails = async () => {
        setLoading(true);
        const response = await fetchData(
          `${ApiConfig.env}`,
          setLoading,
          "post",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (response?.status === 200) {
          login(token);
          navigate("/dashboard");
        }
        setLoading(false);
      };
      getEnvDetails();
    } 
  }, []);

  const handleVideoLoaded = () => {
    setVideoLoaded(true);
  };

  function pauseAndPlayVideo() {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setPlayState(true);
    } else {
      videoRef.current.pause();
      setPlayState(false);
    }
  }
  return (
    <>
      {!loading ? (
        <>
          <Header variation={1}></Header>
          <section className="hero-section">
            <div className="row row-1">
              <div className="video-container" onClick={pauseAndPlayVideo}>
                <img
                  src={tabletFrame}
                  className="tablet-frame"
                  alt="Tablet Frame"
                />
                <video
                  ref={videoRef}
                  className="video-file"
                  width="100%"
                  autoPlay
                  muted
                  loop
                  onLoadedData={handleVideoLoaded}
                  style={{
                    display: videoLoaded ? "block" : "none",
                    width: "100%",
                  }}
                >
                  <source
                    src="https://conceptstorebucket.s3.amazonaws.com/app.onewayx.com/admin/landing-page/Platform-Guide.mp4"
                    type="video/mp4"
                  />
                </video>
                {!videoLoaded && (
                  <img
                    src={placeholderSrc}
                    alt="Video placeholder"
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
                {!playState ? (
                  <PauseIcon
                    className="pause-icon"
                    onClick={() => {
                      videoRef.current.play();
                      setPlayState(true);
                    }}
                  ></PauseIcon>
                ) : null}
              </div>
              <div className="col col-5 col-b" style={{ minWidth: "34%" }}>
                <h2 className="heading">
                  BRING YOUR <br />
                  BRAND EXPERIENCE
                  <br /> TO LIFE
                </h2>
                <p className="text">
                  Transform your 2D websites into 3D immersive virtual stores
                </p>
                <div className="cta-container">
                  <div></div>
                  <div>
                    <Link to="/signup" className="button">
                      <SignupIcon />
                      <span>Sign Up</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
}

export default Home;