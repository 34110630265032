import { Tooltip } from "@mui/material";
import lightIcon from "../../assets/images/ion_rocket-outline.png";
import closeIcon from "../../assets/images/akar-icons_cross.png";
import "../../style/components/popup/PopupPage.scss";

function LaunchPopup2(props) {
  const close = () => {
    props.closePopup(false);
  };

  return (
    <div className="background popup">
      <div className="textContainer">
        <Tooltip title="Close Dialog">
          <img
            src={closeIcon}
            className="close-dialog-icon"
            onClick={close}
            style={{ cursor: "pointer" }}
            alt="close-icon"
          />
        </Tooltip>
        <img src={lightIcon} alt="errorIcon" />
        <h2>Launching ....</h2>
        <p>This may take 2 to 5 days. Please contact support@onewayx.com for assistance.</p>
      </div>
    </div>
  );
}

export default LaunchPopup2;


