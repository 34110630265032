import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import lightIcon from "../../assets/images/light_error.png";
import closeIcon from "../../assets/images/akar-icons_cross.png";
import "../../style/components/popup/PopupPage.scss";

function UpgradePopup(props) {
  const navigate = useNavigate();

  const close = () => {
    props.closePopup(false);
  };

  return (
    <div className="background popup">
      <div className="textContainer">
        <Tooltip title="Close Dialog">
          <img
            src={closeIcon}
            className="close-dialog-icon"
            onClick={close}
            style={{ cursor: "pointer" }}
            alt="close-icon"
          />
        </Tooltip>
        <img src={lightIcon} alt="errorIcon" />
        <h2>Upgrade Plan</h2>
        <p>Upgrade your subscription plan to continue</p>
        <button
          className="button"
          type="button"
          onClick={() => {
            close();
            navigate("/dashboard/subscription");
        }}
        >
          Upgrade{" "}
          <svg
            className="right-arrow"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.8877 6.22237H10.1734M10.1734 6.22237L6.85912 1.81866M10.1734 6.22237L6.85912 10.6261"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default UpgradePopup;

