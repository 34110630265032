import Logo from "../assets/images/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as DownArrowIcon } from "../assets/svg/down-arrow.svg";
import useAuthStore from "../store/authStore";
import ApiConfig from "../helper/ApiConfig";
import { fetchData } from "../fetcher/fetchData";

function Header({ variation }) {
  const [showDropdown, setShowDropdown] = useState(false);
  const [enableStep, setStep] = useState(true);
  const { user,  logout, token } = useAuthStore();
  const [isLoading, setLoading] = useState(false);
  let dynamicHeader = null;
  const location = useLocation();
  const navigate = useNavigate();



  useEffect(() => {
    if(token !== null){
      async function getProductList() {
        setLoading(true);
        const info = await fetchData(
          `${ApiConfig.env}`,
          setLoading,
          "post",
          {},
          { authorization: `Bearer ${token}` }
        );
        if (info?.status === 401 || info?.status === 500) {
          navigate("/login");
        }
        if (info?.status === 200) {
          setStep(
            info.data.data.completed_step >= 4 ||
              info.data.data.brand_param !== ""
          );
        }
        setLoading(false);
      }
      getProductList();
    }
  }, []);


  useEffect(() => {
    function handleClickOutside(event) {
      if (showDropdown && !event.target.closest(".dropdown-menu")) {
        setShowDropdown(false);
      }
    }
    window.addEventListener("click", handleClickOutside);
    return () => window.removeEventListener("click", handleClickOutside);
  }, [showDropdown]);

  async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: 5 },
      { authorization: `Bearer ${token}` }
    );
    if (response?.data?.statusCode === 200) {
      navigate("/dashboard");
    }
  }

  async function handleLogout() {
    setLoading(true);
    const res = await fetchData(
      ApiConfig.logout,
      setLoading,
      "get",
      {},
      { authorization: `Bearer ${token}` }
    );
    if (res) {
      logout();
      localStorage.removeItem("sessionState");
    }
    setLoading(false);
  }

  if (variation === 1) {
    dynamicHeader = (
      <li className="flex-list-item">
        <Link to="/login">
          <button className="button">Login</button>
        </Link>
      </li>
    );
  }

  if (variation === 3) {
    dynamicHeader = (
      <div className="header-content">
        <ul className="nav-list">
          <button
            className="button2"
            type="button"
            style={{
              display:
                location.pathname === "/dashboard/add-game"
                  ? "inline-block"
                  : "none",
              marginRight: "1rem",
              backgroundColor: "#EE3A24",
              borderRadius: "5px",
              height: "2.25vw",
              position: 'relative',
              top: ".42vw",
              padding: " 0 2vw",
              minWidth: "max-content",
            }}
            disabled={!enableStep}
            onClick={updateStep}
          >
            Launch
          </button>
          <li style={{marginRight: '1.1vw', fontSize: '.95vw'}}>Help</li>
          <li className="user-image" style={{ cursor: "pointer" }}>
            <Avatar
              name={`${user?.first_name} ${user?.last_name}`}
              size={31}
              round="0.17316vw"
              onClick={(event) => {
                event.stopPropagation();
                setShowDropdown((prev) => !prev);
              }}
            />
            <span
              className="dropdown-menu"
              onClick={() => {
                setShowDropdown(!showDropdown);
              }}
            >
              <span>
                <DownArrowIcon />
              </span>
              <ul className={showDropdown ? "show" : "dropdown"}>
                <li>
                  <p>
                    {user?.first_name} {user?.last_name}
                    <br />
                    <Tooltip title={user?.brand_email} placement="bottom" arrow>
                      <span className="user-email">{user?.brand_email}</span>
                    </Tooltip>
                  </p>
                </li>
                <li>
                  {/* <a href="/dashboard/company-profile">Company Profile</a> */}
                  <span onClick={() => navigate("/dashboard/company-profile")}>
                    Company Profile
                  </span>
                </li>
                <li onClick={() => handleLogout()}>
                  <span>Logout</span>
                </li>
              </ul>
            </span>
          </li>
        </ul>
      </div>
    );
  }

  return (
    <>
      <header
        className={
          location.pathname === "/play-around" ||
          variation === 2 ||
          variation === 5
            ? "header-absolute"
            : "header"
        }
      >
        <nav>
          <ul>
            <li>
              <Tooltip title="Back to Website">
                <Link
                  to="https://www.onewayx.com/"
                  target="_blank"
                  style={{ display: "flex" }}
                >
                  <img src={Logo} alt="logo" />
                </Link>
              </Tooltip>
            </li>
            {dynamicHeader}
          </ul>
        </nav>
      </header>
      {/* {playVideoPopup && (
        <VideoPopup
          open={playVideoPopup}
          videoUrl={
            "https://conceptstorebucket.s3.amazonaws.com/appzlogic/Platform-Guide.mp4"
          }
          closePopup={handlePopup}
        />
      )} */}
    </>
  );
}

export default Header;
