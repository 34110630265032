import React, { useEffect, useState } from "react";
import "../../../style/components/dashboard/personlisation/Quiz.scss";
import { ReactComponent as EditIcon } from "../../../assets/svg/circum_edit.svg";
import { ReactComponent as DotsIcon } from "../../../assets/svg/three-dots.svg";
import { ReactComponent as LinkIcon } from "../../../assets/svg/bitcoin-icons_link-outline.svg";
import { ReactComponent as ExpandIcon } from "../../../assets/svg/system-uicons_expand.svg";
import LaunchPopup from "../../popup/LaunchPopup";
import { ArrowForward } from "@mui/icons-material";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";

const initialTree = {
  id: "root",
  question: "",
  environmentURL: "",
  environmentName: "",
  hideButton: true,
  disableQuestionButton: false,
  disableEnvButton: false,
  addEnvNode: false,
  children: [],
};

const TreeNode = ({
  node,
  onUpdate,
  onDelete,
  parentHasMultipleChildren,
  variantOptions,
  brandParam,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [newQuestion, setNewQuestion] = useState(node.question);
  const [envURL, setEnvURL] = useState(node.environmentURL || "");
  const [envName, setEnvName] = useState(node.environmentName || "");
  const [isSelectingURL, setIsSelectingURL] = useState(false);
  const { user } = useAuthStore();

  const handleAddChild = () => {
    setButtonVisible(false);
    if (!node.hasAddedAnswer) {
      onUpdate(node.id, {
        children: [
          ...node.children,
          {
            id: `${node.id}-${node.children.length + 1}`,
            question: "",
            environmentURL: "",
            environmentName: "",
            children: [],
            hideButton: false,
            addEnvNode: false,
          },
          {
            id: `${node.id}-${node.children.length + 2}`,
            question: "",
            environmentURL: "",
            environmentName: "",
            children: [],
            hideButton: false,
            addEnvNode: false,
          },
        ],
        hasAddedAnswer: true,
      });
    } else {
      onUpdate(node.id, {
        children: [
          ...node.children,
          {
            id: `${node.id}-${node.children.length + 1}`,
            question: "",
            environmentURL: "",
            environmentName: "",
            children: [],
            hideButton: false,
          },
        ],
      });
    }
  };

  const handleAddQuestion = () => {
    setButtonVisible(false);
    onUpdate(node.id, {
      disableQuestionButton: true,
      disableEnvButton: true,
      children: [
        ...node.children,
        {
          id: `${node.id}-${node.children.length + 1}`,
          question: "",
          environmentURL: "",
          environmentName: "",
          disableQuestionButton: false,
          children: [],
          hideButton: true,
          addEnvNode: false,
        },
      ],
      hasAddedAnswer: true,
    });
  };

  const handleAddEnv = () => {
    setButtonVisible(false);
    onUpdate(node.id, {
      disableEnvButton: true,
      disableQuestionButton: true,
      children: [
        ...node.children,
        {
          id: `${node.id}-${node.children.length + 1}`,
          question: "",
          addEnvNode: true,
          disableQuestionButton: false,
          disableEnvButton: false,
          children: [],
          hideButton: true,
          environmentURL: "",
          environmentName: "",
        },
      ],
    });
  };

  // Handle adding environment URL
  const handleAddEnvURL = () => {
    setIsSelectingURL(false);
    onUpdate(node.id, { environmentURL: envURL , environmentName: envName });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <div
        className="node"
        style={{
          width: "max-content",
          margin: "10px",
          padding: "10px",
          minWidth: "125px",
        }}
      >
        <div>
          {
            <>
              {node.addEnvNode ? (
                <button
                  onClick={() => setIsSelectingURL((prev) => !prev)}
                  className={`text-button ${node.environmentName?.length ? 'env' : ''}`}
                >
                  {node.environmentName?.length ? (
                    <>
                      <LinkIcon /> {node.environmentName}{" "}
                    </>
                  ) : (
                    "Add Enviroment URL"
                  )}
                </button>
              ) : (
                <button
                  className={`text-button ${((node.question.trim().length > 0)  && !node.hideButton) ?  'answer' : ''}`}
                  onClick={() => setIsEditing(true)}
                >
                  {node.question.trim().length > 0 ? (
                    <>
                      {node.question}{" "}
                      <DotsIcon
                        className="dots"
                        onClick={(e) => {
                          e.stopPropagation();
                          setButtonVisible((prev) => !prev);
                        }}
                      />
                    </>
                  ) : node.hideButton ? (
                    <>
                      <EditIcon /> Add Question
                    </>
                  ) : (
                    <>
                      <EditIcon /> Add Answer
                    </>
                  )}
                </button>
              )}
            </>
          }
          {/* Question Text */}
          {isEditing && (
            <div className="set-title">
              <input
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
              />
              <button
                onClick={() => {
                  onUpdate(node.id, { question: newQuestion });
                  setIsEditing(false);
                }}
              >
                Save
              </button>
            </div>
          )}

          {node.addEnvNode && isSelectingURL ? (
            <div className="select-env">
              <p>Add Enviroment URL</p>
              <select
                value={envName}
                onChange={(e) => {                  
                  const selectedKey = Object.keys(variantOptions).find(
                    (key) => variantOptions[key] === e.target.value
                  );
                  setEnvURL(`${process.env.REACT_APP_BASE_URL}/${user.company}/playAround/${brandParam}?variant_id=${selectedKey}`);                  
                  setEnvName(e.target.value);
                }}
              >
                <option value="" disabled>
                  Select an option
                </option>
                {variantOptions &&
                  Object.entries(variantOptions).map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
              </select>
              <button onClick={handleAddEnvURL}>Save</button>
            </div>
          ) : (
            isButtonVisible && (
              <div className="node-action-button">
                {node.hideButton ? (
                  <button onClick={handleAddChild}>Add Answer</button>
                ) : (
                  <>
                    <button
                      onClick={handleAddQuestion}
                      disabled={node.disableQuestionButton}
                    >
                      Add Question
                    </button>
                    <button
                      onClick={handleAddEnv}
                      disabled={node.disableEnvButton}
                    >
                      Add Enviroment
                    </button>
                  </>
                )}

                {!node.hideButton ? (
                  parentHasMultipleChildren && (
                    <button
                      onClick={() => {
                        setButtonVisible(false);
                        onDelete(node.id);
                      }}
                    >
                      Delete
                    </button>
                  )
                ) : (
                  <button
                    onClick={() => {
                      setButtonVisible(false);
                      onDelete(node.id);
                    }}
                  >
                    Delete
                  </button>
                )}
              </div>
            )
          )}
        </div>
      </div>

      {/* Recursive Children */}
      <div
        className={`${node.hideButton ? "child" : "child2"}`}
        style={{ display: "flex" }}
      >
        {node.children &&
          node.children.map((child) => (
            <TreeNode
              key={child.id}
              node={child}
              onUpdate={onUpdate}
              onDelete={onDelete}
              variantOptions={variantOptions}
              brandParam={brandParam}
              parentHasMultipleChildren={node.children.length >= 3}
            />
          ))}
      </div>
    </div>
  );
};

const Quiz = ({ treeData, variantOption, brandParam, closeQuiz }) => {
  const [tree, setTree] = useState(initialTree);
  const [fullScreen, setFullScreen] = useState(false);
  const [openPopup, setPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useAuthStore();

  useEffect(() => {
    if (treeData !== null) {
      setPopupOpen(true);
      setTree(treeData);
    }
  }, []);

  const handlePopup = (data) => {
    setPopupOpen(false);
  };

  const updateNode = (id, updates, currentNode = tree) => {
    if (currentNode.id === id) {
      return { ...currentNode, ...updates };
    }

    return {
      ...currentNode,
      children: currentNode.children.map((child) =>
        updateNode(id, updates, child)
      ),
    };
  };

  const deleteNode = (id, currentNode = tree, parentNode = null) => {
    if (currentNode.id === id) {
      if (parentNode) {
        parentNode.disableQuestionButton = false;
        parentNode.disableEnvButton = false;
      }
      return null;
    }

    return {
      ...currentNode,
      children: currentNode.children
        .map((child) => deleteNode(id, child, currentNode))
        .filter((child) => child !== null),
    };
  };

  const handleUpdate = (id, updates) => {
    setTree((prevTree) => updateNode(id, updates, prevTree));
  };

  const handleDelete = (id) => {
    if (id === "root") {
      setTree(initialTree);
    } else {
      setTree((prevTree) => deleteNode(id, prevTree));
    }
  };

  const handleData = async () => {
    setLoading(true);
    closeQuiz();

    const response2 = await fetchData(
      ApiConfig.quizCommerceUpdate,
      setLoading,
      "POST",
      { quiz_commerce: tree },
      { authorization: `Bearer ${token}` }
    );

    if (response2?.statusCode === 401 || response2?.statusCode === 500) {
      navigate("/login");
    }
    else if(response2?.statusCode === 200 ){
      setPopupOpen(true);
    }
    setLoading(false);
  };

  return (
    <>
     {openPopup ?  
     <>
      <div className={`screen ${fullScreen ? "fullscreen" : ""}`}>
        <div className="expand" onClick={() => setFullScreen((prev) => !prev)}>
          <ExpandIcon />
        </div>
        <div className="bcd">
          <TreeNode
            node={tree}
            onUpdate={handleUpdate}
            onDelete={handleDelete}
            variantOptions={variantOption}
            brandParam={brandParam}
          />
        </div>
      </div>
      <div className="buttons" style={{ justifyContent: "flex-end" }}>
        <button type="button" 
        onClick={() => handleData()}
        >
          {" "}
          Save <ArrowForward />
        </button>
      </div>
      </>
      :
      <LaunchPopup/> 
      }
    </>
  );
};

export default Quiz;
