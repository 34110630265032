import React, { useEffect, useState } from "react";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../helper/Spinner";
import useAuthStore from "../../../store/authStore";
import "../../../style/components/store/ProductAnalytic.scss";

function ProductAnalytic() {
  const [productsList, setProductLists] = useState([]);
  const [loading, setLoading ] = useState(false);
  const { token } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    async function getProductList() {
      const response = await fetchData(
        `${ApiConfig.productList}?page=1&limit=100&variant_id=1`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response?.status === 200) {
        setProductLists(response.data.data);
      } else if (response?.status === 401 || response?.status === 500) {
        navigate("/login");
      }
      setLoading(false);
    }
    getProductList();
  }, []);

  async function updateStep() {
    const response = await fetchData(
      `${ApiConfig.updateStep}`,
      setLoading,
      "PUT",
      { completed_step: 4 },
      { authorization: `Bearer ${token}` }
    );
    if (response?.status === 401 || response?.status === 500) {
      navigate("/login");
    }
    if (response?.data?.statusCode === 200) {
      navigate("/dashboard/add-products");
    }
  }

  return (
    <div className="product-analytics">
      <h2 className="title">Selected Products</h2>
      {!loading ? (
        <>
          <div className="product-details">
            <div className="scrollable-container">
              <table style={{width: '100%'}}>
                <thead>
                  <tr>
                    <th>
                      <p>Product Image</p>
                    </th>
                    <th>
                      <p>Product Title</p>
                    </th>
                    <th>
                      <p>Product ID</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!loading && productsList.length > 0
                    ? productsList.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <div className="image-container">
                              <img
                                src={data.product_image}
                                alt="product-image"
                              ></img>
                            </div>
                          </td>
                          <td>
                            <p>{data.name}</p>
                          </td>
                          <td>
                            <p>{data.product_uid}</p>
                          </td>
                        </tr>
                      ))
                    : Array.from({ length: 5 }).map((_, index) => (
                        <tr key={index}>
                          <td>
                            <div className="image-container-skeleton">
                              <div></div>
                            </div>
                          </td>
                          <td>
                            <p className="paragraph-skeleton"></p>
                          </td>
                          <td>
                            <p className="paragraph-skeleton"></p>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="btn-container">
            <button
              type="button"
              className="button2"
              onClick={() => updateStep()}
            >
              Edit Products
              <svg
                className="right-arrow"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.8877 6.22237H10.1734M10.1734 6.22237L6.85912 1.81866M10.1734 6.22237L6.85912 10.6261"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </>
      ) : (
        <div className="spinner-container">
          <Spinner />
        </div>
      )}
    </div>
  );
}

export default ProductAnalytic;
