import { Canvas } from "@react-three/fiber";
import React, { Suspense, useState } from "react";
import { HDRI_HIGH, HDRI_LOW } from "./HDRI";
import { Environment } from "@react-three/drei";
import { Physics, RigidBody } from "@react-three/rapier";
import { degToRad } from "three/src/math/MathUtils.js";
import { Ground } from "./Ground";
import { EntryCollider } from "./EntryCollider";
import { CustomCameraControls } from "../controls/CustomCameraControls";
import { Building_Products } from "./Building_Products";
import Spinner from "../../../helper/Spinner";
import { Player } from "../controls/Player";
import { Joystick } from "../controls/Joystick";
import ProductCustomizationBar from "../../ProductCustomizationBar";
import ModelProducts from "./ModelProducts";
import useStore from "../util/3dstore";
import { ReactComponent as Reload } from "../../../assets/images/reload.svg";

const Campaign = ({
  variation,
  productData,
  cartProductDetails,
  variationID,
}) => {
  const { uT, isInstructionDone, isEntryAnimationDone } = useStore();
  let isTrue = variation !== 3 ? true : false;

  return (
    <div style={{ height: "100%" }} className="three-screen">
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              zIndex: "999",
            }}
          >
            {" "}
            <Spinner />{" "}
          </div>
        }
      >
        <Joystick variation={variation} />
        <div className="canvas3d" style={{ height: "100%" }}>
          <Canvas
            camera={{ fov: 65 }}
            gl={{ powerPreference: "default", antialias: false }}
          >
            <Suspense fallback={<HDRI_LOW />}>
              <HDRI_HIGH />
            </Suspense>
            <ambientLight intensity={1.5} />
            <Environment background={false} preset="city" />
            <CustomCameraControls />
            <Physics gravity={[0, -20, 0]}>
              <group rotation={[0, degToRad(175), 0]} position={[-28.5, -1, 0]}>
                <Ground />
                <EntryCollider />
                <Building_Products />
                <Player />
              </group>
              <RigidBody type="fixed">
                {productData && (
                  <ModelProducts
                    productData={productData}
                    hasToken={isTrue}
                    cartProductDetails={cartProductDetails}
                  />
                )}
              </RigidBody>
            </Physics>
          </Canvas>
        </div>
        {isInstructionDone && (
          <>
            {variation === 2 &&
              productData !== null &&
              isEntryAnimationDone && (
                <ProductCustomizationBar
                  productData={productData}
                  variationID={variationID}
                />
              )}
            {variation !== 3 && (
              <div
                className="productCustomizationBar-reload"
                onClick={() => uT()}
              >
                <Reload />
              </div>
            )}
          </>
        )}
      </Suspense>
    </div>
  );
};

export default Campaign;
