import React, { useState, useEffect } from "react";
import "../../../style/components/dashboard/personlisation/PersonalisationHome.scss";
import { Add } from "@mui/icons-material";
import { ReactComponent as ArrowIcon } from "../../../assets/svg/arrow.svg";
import SetUpGuide from "../SetUpGuide";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import ApiConfig from "../../../helper/ApiConfig";
import { fetchData } from "../../../fetcher/fetchData";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Quiz from "./Quiz";
import LaunchPopup2 from "../../popup/LaunchPopup2";
import UpgradePopup from "../../popup/UpgradePopup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const PersonalisationHome = () => {
  const [openPopup, setPopupOpen] = useState( false);
  const [openPopupSetupGuide, setOpenPopupSetupGuide] = useState(false);
  const [openUpgadePopup, setUpgradePopup] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);
  const [brandParam, setBrandParam] = useState(null);
  const [varientsVal, setVarientVal] = useState();
  const [varientsDetail, setVarientDetail] = useState(null);
  const [isPersonalization, setPersonalization] = useState(0);
  const [tree, setTree] = useState(null);
  const [envNameDeatil, setEnvDeatil] = useState({
    name: "",
    thumbnail: "",
  });
  const { token, setLoading, user } = useAuthStore();
  const videoSrc =
    "https://conceptstorebucket.s3.amazonaws.com/appzlogic/Platform-Guide.mp4";
  const navigate = useNavigate();

  
  useEffect(() => {
   
    async function getEnv() {
      setLoading(true);
      const info = await fetchData(
        `${ApiConfig.env}`,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info?.status === 200) {
        setBrandParam(info.data.data.brand_param);
        setTree(info?.data?.data?.quiz_commerce);
        setPopupOpen(info?.data?.data?.quiz_commerce !== null)
        setEnvDeatil({
          name: info?.data?.data?.env_template_master?.template_name ?? "",
          thumbnail:
            info?.data?.data?.env_template_master?.video_thumbnail_url ?? "",
        });
        setVarientVal(info?.data?.data?.variant_detail?.length ?? 0);
        setVarientDetail(info?.data?.data?.variant_detail);
        setPersonalization(
          info?.data?.data?.subscriptionDetail?.enhanced_support
        );
      }
      if (info?.status === 401 || info?.status === 500) {
        navigate("/login");
      }
      setLoading(false);
    }
    getEnv();
  }, []);

  const handlePopup = (data) => {
    setPopupOpen(false);
  };

  const handleQuiz = () => {
    setPopupOpen(true);
    setShowQuiz(false);
  };

  const handleUpgrade = () => {
    setUpgradePopup(false);
  };

  function mapVariantsToSelectOptions(variants) {
    const result = {};
    variants.forEach((variant) => {
      const key = variant.variant_id;
      const value = variant.title || "No Title";
      result[key] = value;
    });
    return result;
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },

      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="personlisation">
        <div className="personlisation-header">
          <div>
            <h2>Personalisation</h2>
            <p>Create Virtual Experiences for personalised shopping journeys</p>
          </div>
          <div>
            <button
              className="btn btn-1"
              onClick={() => setOpenPopupSetupGuide(true)}
            >
              View Setup Guide
            </button>
          </div>
        </div>
        <>
          {showQuiz ? (
            <Quiz
              treeData={tree}
              variantOption={mapVariantsToSelectOptions(varientsDetail)}
              brandParam={brandParam}
              closeQuiz={handleQuiz}
            />
          ) : (
            <>
              <div className="personlisation-slider">
                <p className="live-text">Live Environments</p>
                <div className="slider">
                  <Slider {...settings}>
                    {varientsDetail !== null &&
                      varientsDetail.map((data, index) => (
                        <div
                          className="item"
                          key={index}
                          onClick={() =>
                            window.open(
                              `/${user.company}/playAround/${brandParam}?variant_id=${data.variant_id}`,
                              "_blank"
                            )
                          }
                        >
                          <img
                            src={envNameDeatil.thumbnail}
                            alt="img"
                            style={{ cursor: "pointer" }}
                          />
                          <div className="name-tag">
                            <button>
                              {data.title}
                              <ArrowIcon />
                            </button>
                          </div>
                        </div>
                      ))}
                  </Slider>
                </div>{" "}
              </div>
            </>
          )}

          <div
            className="buttons"
            style={{
              justifyContent: `${!showQuiz ? "space-between" : "flex-end"}`,
            }}
          >
            {!showQuiz && (
              <>
                <button
                  type="button"
                  onClick={() => {
                    if (isPersonalization === 1) {
                      navigate("/dashboard/customize-enviroment", {
                        state: {
                          variation: varientsVal + 1,
                          name: envNameDeatil.name,
                        },
                      });
                    } else {
                      setUpgradePopup(true);
                    }
                  }}
                >
                  {" "}
                  <Add />
                  Add Environment
                </button>
                <button

                // disabled = {tree !== null}
                  onClick={() => {
                    if (isPersonalization === 1) {
                      setShowQuiz(true);
                    } else {
                      setUpgradePopup(true);
                    }
                  }}
                >
                  Personalise Shopping Journey
                  <ArrowIcon />
                </button>
              </>
            )}
          </div>
        </>
        {/* )} */}
      </div>
      {openUpgadePopup && <UpgradePopup closePopup={handleUpgrade} />}
      {openPopupSetupGuide && (
        <SetUpGuide
          openPopupSetupGuide={openPopupSetupGuide}
          setOpenPopupSetupGuide={setOpenPopupSetupGuide}
          videoSrc={videoSrc}
        />
      )}
      {openPopup && <LaunchPopup2 closePopup={handlePopup}/>} 
      {/* {openPopup && <LaunchPopup/>}  */}
    </>
  );
};

export default PersonalisationHome;
