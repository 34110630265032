import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import keypad from "../../../assets/images/keypad.png";
import { ReactComponent as PlayIcon } from "../../../assets/svg/playIcon.svg";
import "../../../style/components/game/ActiveGame.scss";
import { fetchData } from "../../../fetcher/fetchData";
import ApiConfig from "../../../helper/ApiConfig";
import useAuthStore from "../../../store/authStore";
import GameCustomize from "./GameCustomize";
import VideoPopup from "../../popup/VideoPopup";

function ActiveGame() {
  const [gameList, setGameList] = useState([]);
  const [gameData, setGameData] = useState([]);
  const [openGamePopup, setOpenGamePopup] = useState(false);
  const [playVideoPopup, setVideoPopup] = useState(false);
  const [gameSelectData, setGameSelectData] = useState("");
  const [currentGame, setCurrentGame] = useState();
  const [selectedGameInfo, setSelectedGameInfo] = useState({});
  const [loading, setLoading ] = useState(false);
  const { token } = useAuthStore();

  const navigate = useNavigate();

  useEffect(() => {
    async function getProductList() {
      setLoading(true);
      const response = await fetchData(
        `${ApiConfig.gameList}?page=1&limit=10'`,
        setLoading,
        "get",
        {},
        {}
      );
      const response2 = await fetchData(
        `${ApiConfig.gameData}`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (response?.status === 200) {
        setGameList(response.data.data);
      } else if (response?.status === 401 || response?.status === 500) {
        navigate("./login");
      }
      if (response2?.status === 200) {
        setCurrentGame(response2.data?.data[0]?.game_id ?? -1);
      } else if (response2?.status === 401 || response2?.status === 500) {
        navigate("./login");
      }
      setLoading(false);
    }
    getProductList();
  }, []);

  const customizeGame = (val) => {
    if (val.game_id === 1) {
      navigate("/dashboard/add-coupon-code");
    } else {
      navigate("/dashboard/spin");
    }
    setGameData(val);
    setOpenGamePopup(true);
  };

  const playPopup = (data) => {
    setGameSelectData(data.game_play_video_url);
    setSelectedGameInfo({
      description: data.game_desc,
    });
    setVideoPopup(!playVideoPopup);
  };
  const handlePopup = (data) => {
    setVideoPopup(data);
  };
  const handleGamePopup = (data) => {
    setOpenGamePopup(false);
  };

  return (
    <div className="active-game">
      <h2 className="title">Active Games</h2>
      <div className="box-container">
        <img src={keypad} alt="keypad" />
        <p>1 Game</p>
      </div>
      <div className="gamification">
        <div className="game-list">
          {loading ? (
            <p>loading...</p>
          ) : (
            gameList.map((game, index) => (
              <div
                key={index}
                className={`details-container ${
                  index + 1 === currentGame ? "selected" : "not-selected"
                } `}
              >
                <div className="img-container" onClick={() => playPopup(game)}>
                  <img
                    src={game.game_thumbnail_url}
                    alt={game.game_name}
                    className="game-img"
                  />
                  <span className="play-icon">
                    <PlayIcon />
                  </span>
                  <span className="title"> {game.game_name}</span>
                </div>

                <div className="game-detail">
                  <h4 className="game-title">{game.game_name}</h4>
                  <div>
                    <p className="game-description">{game.game_desc}</p>
                    <button
                      className="customize"
                      type="button"
                      onClick={() => customizeGame(game)}
                    >
                      {" "}
                      {index + 1 === currentGame
                        ? "Customise Live Game"
                        : "Customise"}{" "}
                      <span>
                        <svg
                          className="right-arrow"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.8877 6.22237H10.1734M10.1734 6.22237L6.85912 1.81866M10.1734 6.22237L6.85912 10.6261"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
        {/* <button
          className="button2"
          type="button"
          onClick={() => navigate('/dashboard/add-game')}
        >
          Edit Gamification
          <svg
            className="right-arrow"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.8877 6.22237H10.1734M10.1734 6.22237L6.85912 1.81866M10.1734 6.22237L6.85912 10.6261"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button> */}

        {openGamePopup && (
          <GameCustomize
            gameData={gameData}
            closeGameCustomize={handleGamePopup}
          />
        )}
      </div>
      {playVideoPopup && (
        <VideoPopup
          open={playPopup}
          videoUrl={gameSelectData}
          closePopup={handlePopup}
          data={selectedGameInfo}
        />
      )}
    </div>
  );
}

export default ActiveGame;
