import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../assets/svg/House_01.svg";
import { ReactComponent as EnvironmentIcon } from "../../assets/svg/Icon.svg";
import { ReactComponent as ProductIcon } from "../../assets/svg/product-icon.svg";
import { ReactComponent as AnalyticsIcon } from "../../assets/svg/analytic-icon.svg";
import { ReactComponent as MerchandiseIcon } from "../../assets/svg/merchandise.svg";
import { ReactComponent as GamificationIcon } from "../../assets/svg/gamification.svg";
import { ReactComponent as LeftArrowIcon } from "../../assets/svg/leftArrow.svg";
import { ReactComponent as RightArrowIcon } from "../../assets/svg/right-arrow.svg";
import { ReactComponent as ShoppingCartIcon } from "../../assets/svg/Shopping_Cart_01.svg";
import { fetchData } from "../../fetcher/fetchData";
import ApiConfig from "../../helper/ApiConfig";
import useAuthStore from "../../store/authStore";

export default function SideNav() {
  const currentUrl = useLocation();
  const [show, setShow] = useState(false);
  const [isActive, setActive] = useState(1);
  const [subVal, setSubVal] = useState(0);
  const [isURL, setURL] = useState("");
  let path =
    currentUrl.pathname.includes("dashboard/") ||
    !currentUrl.pathname.includes("/dashboard");
  let isProductAnalytics = currentUrl.pathname.includes("/product-analytics");
  const { setLoading, token } = useAuthStore();

  useEffect(() => {
    if (window.screen.width < 769) {
      setShow(true);
    }
    if (!path) {
      setShow(false);
    }
    const getEnvDetails = async () => {
      setLoading(false);
      const info = await fetchData(
        ApiConfig.info,
        setLoading,
        "post",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info?.data?.statusCode === 200) {
        setSubVal(info.data.data.envDetail.completed_step);
        setURL(info.data.data.envDetail.brand_param);
      }
      setLoading(true);
    };
    getEnvDetails();
  }, [path]);

  const onToggleExit = () => {
    setShow(!show);
  };

  return (
    <div className={"side-nav " + (path && (show ? " close" : ""))}>
      <ul className="nav-links">
        {currentUrl.pathname.includes("dashboard/") ||
        (!currentUrl.pathname.includes("/dashboard") &&
          !currentUrl.pathname.includes("/congratulation/env")) ? (
          <li onClick={onToggleExit}>
            {" "}
            <NavLink className={show ? "collapse" : ""}>
              {show ? (
                <>
                  <RightArrowIcon />
                </>
              ) : (
                <>
                  <LeftArrowIcon />
                </>
              )}
            </NavLink>
          </li>
        ) : (
          ""
        )}

        <li
          className={isActive === 1 && !isProductAnalytics ? "active_link" : ""}
          onClick={() => setActive(1)}
        >
          <NavLink
            className={show ? "collapse" : ""}
            exact="true"
            to="/dashboard"
          >
            <HomeIcon /> <span className="link_name">Home</span>{" "}
          </NavLink>
        </li>

        <li
          className={isActive === 2 && !isProductAnalytics ? "active_link" : ""}
          onClick={() => setActive(2)}
        >
          <NavLink
            className={show ? "collapse" : ""}
            to={
              subVal === 5 || isURL !== null
                ? "/dashboard/enviroment"
                : "/dashboard/coming-soon"
            }
          >
            <EnvironmentIcon /> <span className="link_name"> Enviroment </span>{" "}
          </NavLink>
        </li>

        <li
          className={isActive === 3 || isProductAnalytics ? "active_link" : ""}
        >
          <NavLink
            className={show ? "collapse" : ""}
            to={
              subVal === 5 || isURL !== null
                ? "/dashboard/product-analytics"
                : "/dashboard/coming-soon"
            }
            onClick={() => setActive(3)}
          >
            <ProductIcon /> <span className="link_name"> Product </span>
          </NavLink>
        </li>

        <li
          className={isActive === 4 && !isProductAnalytics ? "active_link" : ""}
          onClick={() => setActive(4)}
        >
          <NavLink
            className={show ? "collapse" : ""}
            to={
              subVal === 5 || isURL !== null
                ? "/dashboard/customize-store"
                : "/dashboard/coming-soon"
            }
          >
            <MerchandiseIcon /> <span className="link_name"> Merchandise </span>
          </NavLink>
        </li>

        <li
          className={isActive === 5 && !isProductAnalytics ? "active_link" : ""}
          onClick={() => setActive(5)}
        >
          <NavLink
            className={show ? "collapse" : ""}
            to={
              subVal === 5 || isURL !== null
                ? "/dashboard/game"
                : "/dashboard/coming-soon"
            }
          >
            <GamificationIcon />{" "}
            <span className="link_name"> Gamification </span>
          </NavLink>
        </li>
        <li
          className={isActive === 6 && !isProductAnalytics ? "active_link" : ""}
          onClick={() => setActive(6)}
        >
          <NavLink
            className={show ? "collapse" : ""}
            to={
              subVal === 5 || isURL !== null
                ? "/dashboard/analytics"
                : "/dashboard/coming-soon"
            }
            state={{ showQuiz: false }}
          >
            <AnalyticsIcon /> <span className="link_name"> Analytics </span>
          </NavLink>
        </li>

        <li
          className={isActive === 7 && !isProductAnalytics ? "active_link" : ""}
          onClick={() => setActive(7)}
        >
          <NavLink
            className={show ? "collapse" : ""}
            to={
              subVal === 5 || isURL !== null
                ? "/dashboard/personalisation"
                : "/dashboard/coming-soon"
            }
          >
            <ShoppingCartIcon />{" "}
            <span className="link_name"> Personalisation </span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
