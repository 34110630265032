import React, { useEffect, useState } from "react";
import ApiConfig from "../../../helper/ApiConfig";
import { fetchData } from "../../../fetcher/fetchData";
import Spinner from "../../../helper/Spinner";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import { ReactComponent as Left } from "../../../assets/svg/chevron_left.svg";
import { ReactComponent as Right } from "../../../assets/svg/chevron_right.svg";
import "../../../style/components/analytics/ProductAnalytic.scss";

const ProductAnalytic = ({ selectedRange }) => {
  const [productData, setProductData] = useState({});
  const [loading, setLoading ] = useState(false);
  const { token } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    const processData = (data) => {
      let result = {};
      data.data[0].rows.forEach((row) => {
        if (row.dimensionValues) {
          const dimensionValues = row.dimensionValues || [];
          const productName = dimensionValues[3]?.value || "";
          const productImage = dimensionValues[2]?.value || "";
          const productID = dimensionValues[1]?.value || "";
          const productUIDCart = dimensionValues[5]?.value || "";
          const productNameCheckout = dimensionValues[4]?.value || "";
          const productCountCheckout = parseInt(dimensionValues[0]?.value) || 0;

          if (productName && productName !== "(not set)") {
            if (!result[productName]) {
              result[productName] = {
                product: productName,
                imageLink: productImage,
                clicks: 0,
                additions: 0,
                checkouts: 0,
              };
            }
            result[productName].clicks += 1;
          }
          if (productUIDCart && productID) {
            Object.values(result).forEach((product) => {
              if (product.productID === productID) {
                product.additions += parseInt(dimensionValues[7]?.value) || 0;
              }
            });
          }
          if (productNameCheckout && result[productNameCheckout]) {
            if (!isNaN(productCountCheckout)) {
              result[productNameCheckout].checkouts += parseInt(
                productCountCheckout,
                10
              );
            }
          }
        }
      });
      return Object.values(result);
    };
    const getEnvDetails = async () => {
      setLoading(false);
      const info = await fetchData(
        selectedRange && selectedRange.start && selectedRange.end
          ? `${ApiConfig.productReports}?property_id=468803988&start_date=${selectedRange.start}&end_date=${selectedRange.end}`
          : `${ApiConfig.productReports}?property_id=468803988`,
        setLoading,
        "get",
        {},
        { authorization: `Bearer ${token}` }
      );
      if (info?.status === 200) {
        const processedData = processData(info.data);
        setProductData(processedData);
      } else if (info?.status === 401 || info?.status === 500) {
        navigate("/login");
      }
      setLoading(true);
    };
    getEnvDetails();
  }, [selectedRange]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = null;
  let totalPages;
  if (loading && productData.length > 0) {
    currentItems = productData.slice(indexOfFirstItem, indexOfLastItem);
    totalPages = Math.ceil(productData.length / itemsPerPage);
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {loading && (
        <>
          <div className="table-container">
            <table className="product-table">
              <thead>
                <tr>
                  <th rowSpan="2">
                    <p>Product</p>
                  </th>
                  <th colSpan="3">
                    <p>Total no. of</p>
                  </th>
                </tr>
                <tr>
                  <th>
                    <p>Product Page Clicks</p>
                  </th>
                  <th>
                    <p>Additions to Cart</p>
                  </th>
                  <th>
                    <p>Checkouts</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentItems !== null &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className="product-container">
                          <img src={item.imageLink} alt="product_image" />
                          <p>{item.product}</p>
                        </div>
                      </td>
                      <td>
                        <p>{item.clicks}</p>
                      </td>
                      <td>
                        <p>{item.additions}</p>
                      </td>
                      <td>
                        <p>{item.checkouts}</p>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <Left />
            </button>
            {[...Array(totalPages).keys()].map((number) => (
              <button
                key={number + 1}
                onClick={() => handlePageChange(number + 1)}
                className={currentPage === number + 1 ? "active" : ""}
              >
                {number + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <Right />
            </button>
          </div>
        </>
      )}
      {!loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "12vw 0",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ProductAnalytic;
