import React, { useEffect, useState } from "react";
import ApiConfig from "../../helper/ApiConfig";
import { fetchData } from "../../fetcher/fetchData";
import Gabar from "./Gabar";
import useVideoStore from "./util/video";
import { isSafari } from "react-device-detect";
import useAudioContainer from "./util/useAudioContainer";

export default function Env() {
  const [loading, setLoading] = useState(true);
  const [loadingAll, setLoadingAll] = useState(true);
  const [audioFile, setAudioFile] = useState(null);
  const [gameData, setGameData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [cartProductDetails, setCartProductDetails] = useState(null);
  const { handleVideoFileData } = useVideoStore();

  useEffect(() => {
    async function envDetails() {
      setLoadingAll(true);
      setLoading(true);
      const url = window.location.href;
      const values = extractParams(url);
      
      const parts = url.split("/");
      const data = parts[parts.length - 1];
      const response = await fetchData(
        ApiConfig.brandFileMapping,
        setLoading,
        "post",
        {
          "brand_param": values.brand,
          "variant_id": values.variantId,
        },
        {}
      );
      const response2 = await fetchData(
        `${ApiConfig.gameSelection}?brandParam=${values.brand}`,
        setLoading,
        "get",
        {},
        {}
      );

      if (response?.status === 200) {
          handleVideoFileData(
            response?.data?.data?.audio_data[0]?.video_url ?? ""
          );        
        setAudioFile(
          response?.data?.data?.audio_data[0]?.audio_url ?? ""
        );        
        setProductData(response?.data?.data?.product_result || "");
        setCartProductDetails(response?.data?.data?.product || "");
        
      }
      if (response2?.status === 200) {
        setGameData(response2?.data?.data);
      }
      setLoading(false);
      setLoadingAll(false);
    }
    envDetails();
  }, []);

  function extractParams(url) {
    const [baseUrl, queryString] = url.split("?");
    const pathSegments = baseUrl.split("/");
    const brand = pathSegments[5];
    const queryParams = new URLSearchParams(queryString);
    const variantId = queryParams.get("variant_id");
    return { brand, variantId };
  }
  return (
    <>
      {!loading && !loadingAll && (
        <div style={{ height: isSafari? "calc(100vh - 75px)":"100vh" }}>
          <Gabar
            variation={3}
            audioFile={audioFile}
            productData={productData}
            cartProductDetails={cartProductDetails}
            gameData={gameData}
          />
        </div>
      )}
    </>
  );
}
