// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background2 .textContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 65vh;
  width: 60vw;
  margin: 3vw auto 0;
  gap: 10px;
  background-image: linear-gradient(180.14deg, rgba(238, 58, 36, 0.8) 39.22%, rgba(151, 71, 255, 0.8) 65.37%);
  background-color: rgb(255, 255, 255);
}
.background2 .textContainer h2 {
  color: #fff;
  font-weight: 400;
  font-size: 2.1vw;
}
.background2 .textContainer p {
  color: #fff;
  margin: 0vw auto 0.5vw;
  font-weight: 500;
  font-size: 1vw;
}
.background2 .textContainer button {
  background-color: #ee3a24;
  color: #fff;
  font-size: 1vw;
  font-weight: 500;
  padding: 0.75vw 2vw;
}
.background2 .textContainer button svg {
  position: relative;
  top: 1.4px;
  margin-left: 0.4vw;
}
.background2 .textContainer .close-dialog-icon {
  position: absolute;
  top: 0.5vw;
  right: 0.5vw;
}`, "",{"version":3,"sources":["webpack://./src/style/components/popup/LaunchPopup.scss"],"names":[],"mappings":"AACI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,2GAAA;EAmCA,oCAAA;AAlCN;AAIM;EACE,WAAA;EACA,gBAAA;EACA,gBAAA;AAFR;AAIM;EACE,WAAA;EACA,sBAAA;EACA,gBAAA;EACA,cAAA;AAFR;AAIM;EACE,yBAAA;EACA,WAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;AAFR;AAGQ;EACE,kBAAA;EACA,UAAA;EACA,kBAAA;AADV;AAKM;EACE,kBAAA;EACA,UAAA;EACA,YAAA;AAHR","sourcesContent":[".background2 {  \n    .textContainer {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      flex-direction: column;\n      height: 65vh;\n      width: 60vw;\n      margin: 3vw auto 0;\n      gap: 10px;\n      background-image: linear-gradient(\n        180.14deg,\n        rgba(238, 58, 36, 0.8) 39.22%,\n        rgba(151, 71, 255, 0.8) 65.37%\n      );\n      h2 {\n        color: #fff;\n        font-weight: 400;\n        font-size: 2.1vw;\n      }\n      p {\n        color: #fff;\n        margin: 0vw auto 0.5vw;\n        font-weight: 500;\n        font-size: 1vw;\n      }\n      button {\n        background-color: #ee3a24;\n        color: #fff;\n        font-size: 1vw;\n        font-weight: 500;\n        padding: 0.75vw 2vw;\n        svg {\n          position: relative;\n          top: 1.4px;\n          margin-left: 0.4vw;\n        }\n      }\n  \n      .close-dialog-icon {\n        position: absolute;\n        top: 0.5vw;\n        right: 0.5vw;\n      }\n  \n      background-color: rgba(255, 255, 255, 1);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
